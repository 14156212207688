{
  "name": "landing-app",
  "version": "5.0.4-develop.0",
  "scripts": {
    "install": "cp ./package.json ./src/assets",
    "ng": "ng",
    "start": "ng serve --ssl --host localhost.sparbanken.me --port 4201",
    "build": "./scripts/version.sh && ng build",
    "test": "ng test --watch=false --code-coverage",
    "watch": "ng test",
    "lint": "ng lint",
    "style-lint": "stylelint  \"src/**/*.scss\" --fix",
    "coverage": "browser-sync start --server ./coverage/landing-app --watch ''*.html"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.1.4",
    "@angular/cdk": "^19.1.2",
    "@angular/common": "^19.1.4",
    "@angular/compiler": "^19.1.4",
    "@angular/core": "^19.1.4",
    "@angular/forms": "^19.1.4",
    "@angular/material": "^19.1.2",
    "@angular/platform-browser": "^19.1.4",
    "@angular/platform-browser-dynamic": "^19.1.4",
    "@angular/router": "^19.1.4",
    "rxjs": "~7.8.1",
    "tslib": "^2.8.1",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.1.5",
    "@angular/cli": "^19.1.5",
    "@angular/compiler-cli": "^19.1.4",
    "@angular/material-luxon-adapter": "^19.1.2",
    "@sparbanken-syd/auth-backend": "next",
    "@sparbanken-syd/personnummer": "^3.0.0",
    "@sparbanken-syd/sparbanken-syd-bankid": "^19.0.0",
    "@sparbanken-syd/sparbanken-syd-qr": "next",
    "@sparbanken-syd/sparbanken-syd-theme": "^19.0.2",
    "@sparbanken-syd/user-documents-backend": "^1.1.1",
    "@types/jasmine": "~5.1.5",
    "@types/luxon": "^3.4.2",
    "@types/node": "^22.12.0",
    "@types/sparbanken-syd-auth-backend": "^2.3.0",
    "@typescript-eslint/eslint-plugin": "^8.22.0",
    "@typescript-eslint/parser": "^8.22.0",
    "angular-eslint": "^19.0.2",
    "browser-sync": "^3.0.3",
    "eslint": "^9.19.0",
    "eslint-plugin-import": "^2.31.0",
    "eslint-plugin-jsdoc": "^50.6.3",
    "eslint-plugin-prefer-arrow": "^1.2.3",
    "jasmine-core": "~5.5.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "luxon": "^3.5.0",
    "postcss": "^8.5.1",
    "postcss-scss": "^4.0.9",
    "stylelint": "^16.14.1",
    "stylelint-config-standard-scss": "^14.0.0",
    "stylelint-scss": "^6.11.0",
    "typescript": "~5.7.3",
    "typescript-eslint": "^8.22.0",
    "xlsx": "https://cdn.sheetjs.com/xlsx-0.20.0/xlsx-0.20.0.tgz"
  }
}
