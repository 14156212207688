import {Routes} from '@angular/router'
import {authGuard} from './application/auth.guard'
import {
  ADMIN_ROUTE_PATH,
  DEPLOY_ROUTE_PATH,
  HOME_ROUTE_PATH,
  LOGIN_ROUTE_PATH
} from './application/data-types'
import {roleResolver} from './application/role.resolver'
import {siteResolver} from './application/site.resolver'
import {userResolver} from './application/user.resolver'
import {DeployHomeComponent} from './deploy/deploy-home/deploy-home.component'
import deployRoutes from './deploy/deploy-routes'

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: LOGIN_ROUTE_PATH
  },
  {
    path: HOME_ROUTE_PATH,
    loadComponent: () => import('./home/home/home.component').then(m => m.HomeComponent),
    canActivate: [authGuard],
    resolve: {
      sites: siteResolver,
      roles: roleResolver
    }
  },
  {
    path: LOGIN_ROUTE_PATH,
    loadComponent: () => import('./login/login/login.component').then(m => m.LoginComponent)
  },
  {
    path: DEPLOY_ROUTE_PATH,
    children: deployRoutes,
    canActivate: [authGuard]
  },
  {
    path: 'gitlab',
    component: DeployHomeComponent
  },
  {
    path: ADMIN_ROUTE_PATH,
    loadComponent: () => import('./admin/home/home.component').then(m => m.HomeComponent),
    canActivate: [authGuard],
    resolve: {
      users: userResolver,
      roles: roleResolver,
      sites: siteResolver
    }
  },
  {
    path: '**',
    redirectTo: LOGIN_ROUTE_PATH
  }
]