import {inject} from '@angular/core'
import {ResolveFn} from '@angular/router'
import {Observable} from 'rxjs'
import {SiteService} from '../services/site.service'
import {Site} from '../services/types'

export const siteResolver: ResolveFn<Site[]> = (): Observable<Site[]> => {
  const service = inject(SiteService)
  return service.getAllSites()
}

