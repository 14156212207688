import {registerLocaleData} from '@angular/common'
import {provideHttpClient, withInterceptors} from '@angular/common/http'
import localeFr from '@angular/common/locales/fr'
import localeSv from '@angular/common/locales/sv'

import {ApplicationConfig, inject, provideAppInitializer} from '@angular/core'
import {
  LuxonDateAdapter,
  MAT_LUXON_DATE_ADAPTER_OPTIONS,
  MAT_LUXON_DATE_FORMATS
} from '@angular/material-luxon-adapter'
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material/core'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field'
import {MatPaginatorIntl} from '@angular/material/paginator'
import {
  provideAnimationsAsync
} from '@angular/platform-browser/animations/async'
import {
  provideRouter,
  withHashLocation,
  withInMemoryScrolling
} from '@angular/router'
import {SpbPaginatorSv} from '@sparbanken-syd/sparbanken-syd-theme'
import {routes} from './app.routes'
import {LOCAL_STORAGE, WINDOW} from './application/app'
import {authInterceptor} from './application/auth-interceptor'

import {responseInterceptor} from './application/response-interceptor'

import {ConfigService} from './services/config.service'

registerLocaleData(localeFr, 'fr')
registerLocaleData(localeSv)
export const appConfig: ApplicationConfig = {
  providers: [
    {provide: WINDOW, useValue: window},
    {
      provide: LOCAL_STORAGE,
      useFactory: () => localStorage
    },
    ConfigService,
    provideAppInitializer(() => {
      const initializerFn = ((s: ConfigService) => () => s.bootstrap())(inject(ConfigService))
      return initializerFn()
    }),
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        floatLabel: 'always',
        hideRequiredMarker: true,
        appearance: 'outline'
      }
    },
    {provide: MAT_DATE_LOCALE, useValue: 'sv-SE'},
    {provide: MatPaginatorIntl, useClass: SpbPaginatorSv},
    {
      provide: MAT_LUXON_DATE_ADAPTER_OPTIONS,
      useValue: {useUtc: true, firstDayOfWeek: 1}
    },
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS},
    provideHttpClient(withInterceptors([authInterceptor, responseInterceptor])),
    provideAnimationsAsync(),
    provideRouter(
      routes,
      withHashLocation(),
      withInMemoryScrolling({anchorScrolling: 'enabled'})
    )
  ]
}