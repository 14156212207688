import {HttpEvent, HttpHandlerFn, HttpRequest} from '@angular/common/http'
import {inject} from '@angular/core'
import {first, Observable, switchMap} from 'rxjs'
import {ConfigService} from '../services/config.service'
import {DeployService} from '../services/deploy.service'

/**
 * Intercept all requests and add auth token. We should however
 * do this only on white listed requests so that we do not
 * send our tokens when not needed. In its simplest form this
 * assumes that all outgoing requests after the token is set
 * should have it.
 */
export const authInterceptor = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  const deployService = inject(DeployService)
  const configService = inject(ConfigService)
  return configService.accessToken$.pipe(
    first(),
    switchMap(token => {
      if (!token) {
        return next(req)
      }

      let headers = req.headers
      if (req.url.indexOf('/deploy') !== -1) {
        headers = headers.set('X-GitLabApi-Token', deployService.getGitLabToken())
      }

      const authReq = req.clone({
        headers: headers.set('Authorization', `Bearer ${token}`)
      })
      return next(authReq)
    })
  )
}