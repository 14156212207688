<div matDialogTitle>{{ data.title }}</div>
<div matDialogContent>

  <div *ngIf="loadingProjects && !data.gitDatabaseProject"
       class="load-projects">Vänligen vänta medan
    projekten laddas...
  </div>

  <button
    (click)="getProjectsFromGitLab()"
    *ngIf="!loadingProjects && !data.gitDatabaseProject"
    class="load-projects"
    mat-raised-button>Ladda projekt från GitLab
  </button>

  <div>
    <spb-auto-add-project
      (gitProject)="selectedProject($event)"
      *ngIf="deployService.iGitLabProjects$().length > 0"
      [gitDatabaseProjects]="data.gitDatabaseProjects"
      [gitLabProjects]="deployService.iGitLabProjects$()"
    ></spb-auto-add-project>
  </div>

  <form [formGroup]="gitProjectForm" class="form-container">
    <div>
      <mat-form-field>
        <mat-label>Projektnummer</mat-label>
        <label>
          <input formControlName="projectKey" matInput>
        </label>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field>
        <mat-label>Projektnamn</mat-label>
        <label>
          <input formControlName="projectName" matInput>
        </label>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field>
        <mat-label>Beskrivning</mat-label>
        <textarea
          cdkAutosizeMaxRows="5"
          formControlName="projectDescription"
          matInput></textarea>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field>
        <mat-label>Välj fil</mat-label>
        <mat-select
          [placeholder]="gitLabFiles$.value.length ? 'Välj fil' : 'Laddar filer'"
          formControlName="packageJson"
          ngDefaultControl>
          <mat-option *ngFor="let option of gitLabFiles$ | async"
                      [value]="option.path">{{ option.path }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>

  <div matDialogActions>
    <button
      (click)="saveNewProject()"
      [disabled]="!gitProjectForm.valid"
      color="primary"
      mat-raised-button type="button">Spara
    </button>
    <button [mat-dialog-close]="undefined" mat-raised-button type="button">
      Stäng
    </button>
  </div>
</div>
